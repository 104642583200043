.disclaimer {
  position: fixed;
  display: flex;
  justify-content: space-between;
  align-items: center;
  bottom: 0;
  left: 0;
  right: 0;
  background: #eeeeee;
  width: 100vw;
  padding: 8px 20px;
  height: 70px;
  z-index: 99999;

  .disclaimer-text {
    font-weight: 600;
    font-size: 18px;
    color: #3f3f3f;
    white-space: normal;
    margin-right: 20px;

    @media (max-width: 500px) {
      font-size: 90% !important;
    }
  }

  .disclaimer-link, .disclaimer-link:hover, .disclaimer-link:visited {
    font-weight: 900;
    font-size: 18px;
    color: #603f98;
    text-decoration: underline;

    &:hover {
      text-decoration: none;
    }

    @media (max-width: 500px) {
      font-size: 90% !important;
    }
  }

  .button {
    @include button();
    line-height: 55px;

    @media (max-width: 500px) {
      line-height: 37px;
      height: 40px;
      font-size: 15px;
      width: 140px;
    }
  }
}
