.formWrapper {
  .rmd-menu--select-field {
    width: 100%;
    margin: 0;
  }

  form {

    .rmd-divider {
      background-color: rgb(151, 151, 151);
    }

    .rmd-text-field-container {
      margin-top: 30px;
    }

    .rmd-floating-label {
      color: rgb(63, 63, 63);
      font-size: 16px;
      font-weight: 400;
      padding-left: 0;
    }

    .rmd-layover {
      width: 100%;
      margin: 0;
      padding: 0;

      &#mode-menu {
        border-bottom: 1px solid rgb(151, 151, 151);
      }

      .rmd-select-field--button {
        color: rgb(63, 63, 63);
        font-size: 18px;
        font-weight: 700;
        padding-left: 10px;
      }

      .rmd-icon-separator {
        padding-right: 0;
        margin-top: 28px;
      }

      div.rmd-text--error {
        position: relative;
        top: 23px;
      }
    }

    div.rmd-text--disabled,
    div.rmd-text-field-message--inactive {
      height: 0;
    }

    .rmd-tile-content {
      .rmd-text {
        color: rgb(63, 63, 63);
        font-size: 18px;
        font-weight: 700;
      }
    }
  }

  .cta {
    display: flex;
    flex-direction: row;
    justify-content: center;

    button.rmd-button {
      @include button();
      letter-spacing: 1.5px;
      text-transform: uppercase;
      display: inline-block;
      width: 40%;
      margin-top: 20px;
      font-size: 22px;
      padding: 30px 10px;
      line-height: 2px;
      border-radius: 45px;

      &.rmd-text--disabled {
        background: rgb(200, 200, 200);
        cursor: default;
      }
    }
  }
}

@media (max-width: 500px) {
  .formWrapper .cta button.rmd-button {
    width: 80%;
  }
}
