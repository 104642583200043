.header {
  height: 140px;
  display: flex;
  flex-direction: row;
  background-image: linear-gradient(to right, rgb(123, 194, 195), rgb(128, 133, 191));
 
  & > * {
    width: 33%;
  }

  img.logo {
    margin: 2% 10px;
  }

  span {
    font-size: 30px;
    font-weight: 700;
    color: #fff;
    margin: auto 0;
    display: flex;
    justify-content: center;
  }

  .qr {
    display: flex;
    justify-content: flex-end;

    img {
      height: 100%;
      width: 140px;
    }
  }
}

@media (max-width: 850px) and (min-width: 601px) {
  .header {
    height: 100px;

    img.logo {
      margin: 4% 10px;
    }

    span {
      font-size: 24px;
    }

    .qr {
      img {
        width: 100px;
      }
    }
  }
}

@media (max-width: 600px) {
  .header {
    height: 70px;

    img.logo {
      margin: 3% 10px;
    }

    span {
      font-size: 18px;
    }

    .qr {
      img {
        width: 70px;
      }
    }
  }
}

@media (max-width: 400px) {
  .header {
    img.logo {
      margin: 6% 10px;
    }

    span {
      width: 100%;
    }
  }
}
